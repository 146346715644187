var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"name":"Proveedor","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Proveedor"},model:{value:(_vm.input.supplier),callback:function ($$v) {_vm.$set(_vm.input, "supplier", $$v)},expression:"input.supplier"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"name":"NIT","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"NIT"},model:{value:(_vm.input.nit),callback:function ($$v) {_vm.$set(_vm.input, "nit", $$v)},expression:"input.nit"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-full"},[_c('ValidationProvider',{attrs:{"name":"Descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{attrs:{"label":"Descripción"},model:{value:(_vm.input.description),callback:function ($$v) {_vm.$set(_vm.input, "description", $$v)},expression:"input.description"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('label',{staticClass:"text-sm text-san-juan font-bold"},[_vm._v("Fecha")]),_c('ValidationProvider',{attrs:{"name":"Fecha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"label":"Fecha","language":_vm.langEs,"name":"start-date"},model:{value:(_vm.input.date),callback:function ($$v) {_vm.$set(_vm.input, "date", $$v)},expression:"input.date"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('label',{staticClass:"text-sm text-san-juan font-bold"},[_vm._v("Hora")]),_c('vs-input',{staticClass:"w-full",attrs:{"type":"time"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"name":"Monto","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Monto"},model:{value:(_vm.input.amount),callback:function ($$v) {_vm.$set(_vm.input, "amount", $$v)},expression:"input.amount"}}),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-full w-full mb-6"},[_c('ValidationProvider',{attrs:{"name":"Tipos de movimiento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"label":"Tipos de movimiento"},model:{value:(_vm.input.transaction_type),callback:function ($$v) {_vm.$set(_vm.input, "transaction_type", $$v)},expression:"input.transaction_type"}},_vm._l((_vm.transaction_types),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text}})}),1),_c('div',{staticClass:"text-danger text-sm leading-tight"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-full mb-6"},[_c('div',{staticClass:"flex flex-wrap justify-end"},[_c('vs-button',{staticClass:"mr-3 mb-2",attrs:{"disabled":invalid},on:{"click":_vm.addNote}},[_vm._v("Guardar")]),_c('vs-button',{staticClass:"mb-2",attrs:{"color":"primary","type":"border"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Descartar")])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
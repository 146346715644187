<template>
  <vx-card>
    <ValidationObserver v-slot="{ invalid }">
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-6">
          <ValidationProvider
            name="Proveedor"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <vs-input class="w-full" label="Proveedor" v-model="input.supplier">
            </vs-input>
            <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-6">
          <ValidationProvider
            name="NIT"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <vs-input class="w-full" label="NIT" v-model="input.nit">
            </vs-input>
            <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="vx-col w-full">
          <ValidationProvider
            name="Descripción"
            rules="required"
            v-slot="{ errors }"
          >
            <vs-textarea
              label="Descripción"
              v-model="input.description"
            ></vs-textarea>
            <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-6">
          <label class="text-sm text-san-juan font-bold">Fecha</label>
          <ValidationProvider name="Fecha" rules="required" v-slot="{ errors }">
            <datepicker
              label="Fecha"
              :language="langEs"
              name="start-date"
              v-model="input.date"
            ></datepicker>
            <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-6">
          <label class="text-sm text-san-juan font-bold">Hora</label>
          <!-- <flat-pickr
            :config="configdateTimePicker"
            v-model="time"
            :modelValue="time"
            class="w-full"
            label="Hora inicio"
            placeholder="00:00"
          /> -->
          <vs-input class="w-full" type="time" v-model="time" />
        </div>
        <div class="vx-col md:w-1/2 w-full mb-6">
          <ValidationProvider
            name="Monto"
            rules="required|numeric"
            v-slot="{ errors }"
          >
            <vs-input
              class="w-full"
              label="Monto"
              v-model="input.amount"
            ></vs-input>
            <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="vx-col md:w-full w-full mb-6">
          <ValidationProvider
            name="Tipos de movimiento"
            rules="required"
            v-slot="{ errors }"
          >
            <vs-select
              class="w-full"
              label="Tipos de movimiento"
              v-model="input.transaction_type"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in transaction_types"
              />
            </vs-select>
            <div class="text-danger text-sm leading-tight">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="vx-col w-full mb-6">
          <div class="flex flex-wrap justify-end">
            <vs-button class="mr-3 mb-2" :disabled="invalid" @click="addNote"
              >Guardar</vs-button
            >
            <vs-button
              @click="$router.go(-1)"
              color="primary"
              type="border"
              class="mb-2"
              >Descartar</vs-button
            >
          </div>
        </div>
      </div>
    </ValidationObserver>
  </vx-card>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { es } from 'vuejs-datepicker/src/locale';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import { CREATE_ACCOUNT_MOVES } from '@/graphql/mutations';
import { TRANSACTIONS, GET_TOTAL_TYPES } from '@/graphql/queries';
export default {
  data() {
    return {
      langEs: es,
      transaction_types: [
        {
          text: 'Ingreso',
          value: 'INCOME',
        },
        {
          text: 'Egreso',
          value: 'EXPEND',
        },
      ],
      input: {
        account_id: this.$route.params.accountId,
        supplier: '',
        nit: '',
        transaction_type: '',
        description: '',
        date: new Date(),
        amount: 0.0,
      },
      configdateTimePicker: {
        enableTime: true,
        defaultDate: new Date(),
        enableSeconds: true,
        noCalendar: true,
      },
      time: moment(new Date()).format('HH:mm:ss'),
    };
  },
  methods: {
    addNote() {
      this.input.date =
        moment(this.input.date).format('YYYY-MM-DD') + ' ' + this.time;
      this.input.amount = parseFloat(this.input.amount);

      console.log(
        '🚀 ~ file: AgregarNota.vue ~ line 168 ~ addNote ~ this.input.date',
        this.input.date
      );
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: CREATE_ACCOUNT_MOVES,
          variables: {
            input: this.input,
          },
        })
        .then(async () => {
          const sDate = moment().format('YYYY-MM-DD');
          const eDate = moment().format('YYYY-MM-DD');
          const transactionTypes = [
            'RECHARGE',
            'DISCHARGE',
            'INCOME',
            'EXPEND',
          ];
          await this.$apollo.provider.defaultClient.query({
            query: TRANSACTIONS,
            fetchPolicy: 'network-only',
            variables: {
              page: 1,
              first: 15,
              startDate: sDate,
              account_id: this.$route.params.accountId,
              endDate: eDate,
              types: transactionTypes,
            },
          });
          await this.$apollo.provider.defaultClient.query({
            query: GET_TOTAL_TYPES,
            fetchPolicy: 'network-only',
            variables: {
              startDate: sDate,
              account_id: this.$route.params.accountId,
              endDate: eDate,
            },
          });
          this.$router.go(-1);
          this.$vs.notify({
            color: 'success',
            title: 'Nota contable creada!',
            text: 'La nota contable fue correctamente creada.',
          });
        })
        .catch((error) => {
          this.$vs.notify({
            color: 'danger',
            title: 'No se pudo crear la nota contable.',
            text: `${error.graphQLErrors[0].message}`,
          });
        });
    },
  },
  components: {
    Datepicker,
    ValidationProvider,
  },
  mehtod() {},
};
</script>

<style></style>
